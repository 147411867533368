import "./App.css";
import React, { useState, CSSProperties } from "react";
import GridLoader from "react-spinners/GridLoader";
import Modal from "./modal";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCoffee, faQuestion } from "@fortawesome/free-solid-svg-icons";
import DaVinciPromptHelp from "./davinciprompthelp";
import { config } from "./Constants";
import Sidebar from "./Sidebar";

function Summarizr() {
  const [textToSend, setTextToSend] = useState("");
  const [textReceived, setTextReceived] = useState("");
  const [loading, setLoading] = useState(false);
  const [loadingError, setError] = useState(0);
  const [errorMessage, setErrorMessage] = useState("");
  const [errorTitle, setErrorTitle] = useState("");
  const [result, setResult] = useState(false);
  const [validInput, setValidInput] = useState(0);
  const [prompt, setPrompt] = useState("");

  const [showSidebar, setShowSidebar] = useState(false);

  const [conversation, setConversation] = useState([
    { role: "system", content: "You are a helpful assistant." },
  ]);

  const conversationElement = conversation.map((turn, index) => {
    if (turn.role === "system") return;
    else if (turn.role === "user")
      return (
        <div key={index} className="pb-1">
          <span>
            <b>You: </b>
          </span>
          <span>{turn.content}</span>
        </div>
      );
    else
      return (
        <div key={index} className="pb-1">
          <span>
            <b>ChatGPT: </b>
          </span>
          <span>{turn.content}</span>
        </div>
      );
  });

  const minimumValidTextLength = 10;

  const override: CSSProperties = {
    display: "block",
  };

  const openModal = (event) => {
    var modal = document.querySelector(".modal");
    modal.classList.toggle("modal-open");
  };

  const keyDown = (event) => {
    if (event.code === "Enter") {
      console.log("Enter");
      event.stopPropagation();
      event.preventDefault();
      event.nativeEvent.stopImmediatePropagation();
      processPrompt();
      return false;
    }
  };

  const textChanged = (event) => {
    setPrompt(event.target.value);
    //setTextToSend(event.target.value);
    if (event.target.value.length > minimumValidTextLength) {
      setValidInput(1);
    } else {
      setValidInput(0);
    }
    event.preventDefault();
  };

  const processPrompt = () => {
    setPrompt("");
    let newTurn = {
      role: "user",
      content:
        "Sammanfatta följande text i punkt form:" +
        prompt.replace(/[\r\n]/gm, ""),
    };
    let newConversation = conversation;
    newConversation.push(newTurn);
    setConversation(newConversation);

    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({ prompt: conversation }),
    };

    //https://reqres.in/api/posts
    setTextToSend("");
    setLoading(true);
    setResult(false);
    console.log(requestOptions);
    fetch(config.url.GPT_3POINT5_TURBO_URL, requestOptions)
      .then((response) => {
        const isJson = response.headers
          .get("content-type")
          ?.includes("application/json");
        const data = isJson ? response.json() : null;

        if (!response.ok) {
          // get error message from body or default to response status
          const error = (data && data.message) || response.status;
          return Promise.reject(error);
        }
        return data;
      })
      .then((data) => {
        console.log(data);
        let receivedText = data.choices[0].message;

        let newConversation = conversation;
        newConversation.push(receivedText);
        setConversation(newConversation);

        setResult(true);
        setLoading(false);
        if (receivedText.content.length == 0) {
          setErrorTitle("Tomt svar.");
          setErrorMessage(
            "GPT-3 algoritmen returnerade ett tomt svar. Detta är inget fel sig - prova att skicka om din prompt."
          );
          setError(1);
        }
      })
      .catch((error) => {
        console.log(error);

        setErrorTitle("Ett problem har uppstått.");
        setErrorMessage(
          "Tyvärr gick det inte att nå servern just nu. Var god försök igen senare"
        );
        setError(1);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const clickedButton = (event) => {
    event.stopPropagation();
    event.nativeEvent.stopImmediatePropagation();
    processPrompt();
  };

  const ResultsForm = () => {
    return (
      <form>
        <label htmlFor="response">Svar:</label>
        <textarea
          id="response"
          style={{ height: 30 + "rem" }}
          readOnly
          value={textReceived}
          className="u-full-width"
        ></textarea>
      </form>
    );
  };

  const LoadingSpinner = () => {
    return (
      <div className="box">
        <GridLoader loading={loading} cssOverride={override} />
      </div>
    );
  };

  const SendButton = () => {
    if (validInput) {
      return (
        <button
          type="button"
          className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
          onClick={clickedButton}
        >
          Skicka
        </button>
      );
    } else {
      return (
        <button
          type="button"
          disabled
          className="bg-gray-500 hover:bg-gray-700 text-white font-bold py-2 px-4 rounded"
          onClick={clickedButton}
        >
          Skicka
        </button>
      );
    }
  };

  return (
    <div className="text-gray-900 dark:text-white">
      <div class="p-4 sm:ml-64">
        <div class="p-4 border-2 border-gray-200 border-dashed rounded-lg dark:border-gray-700 mt-14">
          <h2>Summarizr</h2>
          <p>Skriv en text att summera.</p>
          {conversationElement}
          <form className="pt-2">
            <textarea
              spellCheck="false"
              id="prompt"
              className="block p-2.5 w-full text-sm text-gray-900  rounded-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
              placeholder="Din prompt"
              onChange={textChanged}
              onKeyDown={keyDown}
              value={prompt}
            ></textarea>
            {
              loading ? (
                <LoadingSpinner />
              ) : (
                <div></div>
              ) /*<SendButton></SendButton>*/
            }
          </form>
          {/*result ? <ResultsForm /> : null*/}
        </div>
      </div>

      <Modal
        closingFunction={setError}
        visible={loadingError}
        title={errorTitle}
        content={errorMessage}
      ></Modal>
    </div>
  );
}

export default Summarizr;
