// Constants.js
const prod = {
  url: {
    API_URL: "https://server.edgpt.teed.se/gpt3",
    GPT_3POINT5_TURBO_URL: "https://server.edgpt.teed.se/gpt-3.5-turbo",
    DALLE_URL: "https://server.edgpt.teed.se/dalle",
  },
};
const dev = {
  url: {
    API_URL: "http://localhost:5432/gpt3",
    GPT_3POINT5_TURBO_URL: "http://localhost:5432/gpt-3.5-turbo",
    DALLE_URL: "http://localhost:5432/dalle",
  },
};
export const config = process.env.NODE_ENV === "development" ? dev : prod;
