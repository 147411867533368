import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import "./App.css";
import Gpt3davinci from "./gpt3davinci";
import ChatGPT from "./chatgpt";
import reportWebVitals from "./reportWebVitals";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Sidebar from "./Sidebar";
import ReactGA from "react-ga4";
import Summarizr from "./summarizr";
import Expandr from "./expandr";
import Dalle from "./dalle";
import Questionizr from "./questionizr";

ReactGA.initialize("G-WG9C440L9Z");
ReactGA.send({
  hitType: "pageview",
  page: window.location.pathname + window.location.search,
});

const switchMode = () => {
  console.log("switching Mode");
  let rootTag = document.querySelector(":root");
  console.log(rootTag);
  rootTag.classList.toggle("dark");
};

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <nav className="fixed top-0 z-50 w-full bg-white border-b border-gray-200 dark:bg-gray-800 dark:border-gray-700">
      <div className="px-3 py-3 lg:px-5 lg:pl-3">
        <div className="flex items-center justify-start">
          {/*     <button
            data-drawer-target="logo-sidebar"
            data-drawer-toggle="logo-sidebar"
            aria-controls="logo-sidebar"
            type="button"
            className="inline-flex items-center p-2 text-sm text-gray-500 rounded-lg md:hidden hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-gray-200 dark:text-gray-400 dark:hover:bg-gray-700 dark:focus:ring-gray-600"
          >
            <span className="sr-only">Open sidebar</span>
            <svg
              className="w-6 h-6"
              aria-hidden="true"
              fill="currentColor"
              viewBox="0 0 20 20"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                clipRule="evenodd"
                fillRule="evenodd"
                d="M2 4.75A.75.75 0 012.75 4h14.5a.75.75 0 010 1.5H2.75A.75.75 0 012 4.75zm0 10.5a.75.75 0 01.75-.75h7.5a.75.75 0 010 1.5h-7.5a.75.75 0 01-.75-.75zM2 10a.75.75 0 01.75-.75h14.5a.75.75 0 010 1.5H2.75A.75.75 0 012 10z"
              ></path>
            </svg>
          </button> */}
          <a href="https://edgpt.teed.se" className="flex ml-2 md:mr-24">
            <img
              id="logo"
              src="brain.png"
              className="h-6 mr-3 sm:h-9"
              alt="ED-GPT Logo"
            />
            <span className="self-center text-xl font-semibold sm:text-2xl whitespace-nowrap dark:text-white">
              ED-GPT
            </span>
          </a>
        </div>
        {/*    <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          strokeWidth="1.5"
          stroke="currentColor"
          className="w-6 h-6"
          onClick={switchMode}
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M21.752 15.002A9.718 9.718 0 0118 15.75c-5.385 0-9.75-4.365-9.75-9.75 0-1.33.266-2.597.748-3.752A9.753 9.753 0 003 11.25C3 16.635 7.365 21 12.75 21a9.753 9.753 0 009.002-5.998z"
          />
        </svg> */}
      </div>
    </nav>

    <div className="container rounded-xl dark:bg-gray-800">
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<ChatGPT />} />
          <Route path="chatgpt" element={<ChatGPT />} />
          <Route path="expandr" element={<Expandr />} />
          <Route path="summarizr" element={<Summarizr />} />
          <Route path="dalle" element={<Dalle />} />
          <Route path="questionizr" element={<Questionizr />} />
        </Routes>
      </BrowserRouter>
    </div>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
