import "./App.css";
import React, { useState, CSSProperties } from "react";
import GridLoader from "react-spinners/GridLoader";
import MyModal from "./modal";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCoffee, faQuestion } from "@fortawesome/free-solid-svg-icons";
import DaVinciPromptHelp from "./davinciprompthelp";
import { config } from "./Constants";
import Sidebar from "./Sidebar";
import { Modal } from "flowbite";

function Dalle() {
  const [textToSend, setTextToSend] = useState("");
  const [textReceived, setTextReceived] = useState("");
  const [loading, setLoading] = useState(false);
  const [loadingError, setError] = useState(0);
  const [errorMessage, setErrorMessage] = useState("");
  const [errorTitle, setErrorTitle] = useState("");
  const [result, setResult] = useState(false);
  const [validInput, setValidInput] = useState(0);
  const [prompt, setPrompt] = useState("");
  const [modal, setModal] = useState(null);

  const [showSidebar, setShowSidebar] = useState(false);

  const [conversation, setConversation] = useState([
    { role: "system", content: "You are a helpful assistant." },
  ]);

  const processDownload = (event) => {
    download(event.target.url);
    event.stopPropagation();
    event.stopImmediatePropagation();
  };

  const conversationElement = conversation.map((turn, index) => {
    if (turn.role === "system") return;
    else if (turn.role === "user")
      return (
        <div key={index} className="pb-1">
          <span>
            <b>You: </b>
          </span>
          <span>{turn.content}</span>
        </div>
      );
    else
      return (
        <div key={index} className="pb-1">
          <span>
            <b>DALL-E: </b>
          </span>
          <img src={turn.content} alt={prompt} />
          <a
            href={turn.content}
            download="proposed_file_name"
            className="bg-gray-300 hover:bg-gray-400 text-gray-800 font-bold py-2 px-4 rounded inline-flex items-center"
            onClick={processDownload}
          >
            <svg
              className="fill-current w-4 h-4 mr-2"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 20 20"
            >
              <path d="M13 8V2H7v6H2l8 8 8-8h-5zM0 18h20v2H0v-2z" />
            </svg>
            <span>Download</span>
          </a>
        </div>
      );
  });

  const minimumValidTextLength = 10;

  const override: CSSProperties = {
    display: "block",
  };

  const openModal = (event) => {
    var modal = document.querySelector(".modal");
    modal.classList.toggle("modal-open");
  };

  const keyDown = (event) => {
    if (event.code === "Enter") {
      console.log("Enter");
      event.stopPropagation();
      event.preventDefault();
      event.nativeEvent.stopImmediatePropagation();
      processPrompt();
      return false;
    }
  };

  const textChanged = (event) => {
    setPrompt(event.target.value);
    //setTextToSend(event.target.value);
    if (event.target.value.length > minimumValidTextLength) {
      setValidInput(1);
    } else {
      setValidInput(0);
    }
    event.preventDefault();
  };

  const download = (url) => {
    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        url: url,
      }),
    };

    fetch(config.url.DALLE_URL + "/download", requestOptions)
      .then((response) => {
        if (!response.ok) {
          // get error message from body or default to response status
          const error = response.status;
          return Promise.reject(error);
        }
      })
      .then((data) => {})
      .catch((error) => {
        console.log(error);

        setErrorTitle("Ett problem har uppstått.");
        setErrorMessage(
          "Tyvärr gick det inte att nå servern just nu. Var god försök igen senare"
        );
        ShowModal();
        setError(1);
      });
  };

  const processPrompt = () => {
    setPrompt("");

    let newTurn = { role: "user", content: prompt.replace(/[\r\n]/gm, "") };
    let newConversation = conversation;
    newConversation.push(newTurn);
    setConversation(newConversation);

    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        prompt: prompt.replace(/[\r\n]/gm, ""),
        number: 1,
        size: "1024x1024",
      }),
    };

    //https://reqres.in/api/posts
    setTextToSend("");
    setLoading(true);
    setResult(false);
    console.log(requestOptions);
    fetch(config.url.DALLE_URL, requestOptions)
      .then((response) => {
        const isJson = response.headers
          .get("content-type")
          ?.includes("application/json");
        const data = isJson ? response.json() : null;

        if (!response.ok) {
          // get error message from body or default to response status
          const error = (data && data.message) || response.status;
          return Promise.reject(error);
        }
        return data;
      })
      .then((data) => {
        console.log(data);
        let receivedText = data.data[0].url;
        console.log(receivedText);
        let newConversation = conversation;
        newConversation.push({ role: "dalle", content: receivedText });
        setConversation(newConversation);

        setResult(true);
        setLoading(false);
        if (receivedText.length == 0) {
          setErrorTitle("Tomt svar.");
          setErrorMessage(
            "GPT-3 algoritmen returnerade ett tomt svar. Detta är inget fel sig - prova att skicka om din prompt."
          );

          setError(1);
        }
      })
      .catch((error) => {
        console.log(error);

        setErrorTitle("Ett problem har uppstått.");
        setErrorMessage(
          "Tyvärr gick det inte att nå servern just nu. Var god försök igen senare"
        );
        setError(1);
        ShowModal();
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const clickedButton = (event) => {
    event.stopPropagation();
    event.nativeEvent.stopImmediatePropagation();
    processPrompt();
  };

  const ResultsForm = () => {
    return (
      <form>
        <label htmlFor="response">Svar:</label>
        <textarea
          id="response"
          style={{ height: 30 + "rem" }}
          readOnly
          value={textReceived}
          className="u-full-width"
        ></textarea>
      </form>
    );
  };

  const LoadingSpinner = () => {
    return (
      <div className="box">
        <GridLoader loading={loading} cssOverride={override} />
      </div>
    );
  };

  const HideModal = () => {
    console.log("Calling hide");
    modal.hide();
  };

  const ShowModal = () => {
    // set the modal menu element
    const targetEl = document.getElementById("defaultModal");

    console.log(targetEl);
    // options with default values
    const options = {
      placement: "center-center",
      backdrop: "dynamic",
      backdropClasses:
        "bg-gray-900 bg-opacity-50 dark:bg-opacity-80 fixed inset-0 z-40",
      closable: true,
      onHide: () => {
        console.log("modal is hidden");
      },
      onShow: () => {
        console.log("modal is shown");
      },
      onToggle: () => {
        console.log("modal has been toggled");
      },
    };

    let modal = new Modal(targetEl, options);
    setModal(modal);

    modal.show();
  };

  const SendButton = () => {
    if (validInput) {
      return (
        <button
          type="button"
          className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
          onClick={clickedButton}
        >
          Skicka
        </button>
      );
    } else {
      return (
        <button
          type="button"
          disabled
          className="bg-gray-500 hover:bg-gray-700 text-white font-bold py-2 px-4 rounded"
          onClick={clickedButton}
        >
          Skicka
        </button>
      );
    }
  };

  return (
    <div className="text-gray-900 dark:text-white">
      <div className="p-4 sm:ml-64">
        <div className="p-4 border-2 border-gray-200 border-dashed rounded-lg dark:border-gray-700 mt-14">
          <h2>DALL-E</h2>
          {conversationElement}
          <button onClick={ShowModal}>Show Modal</button>
          <form className="pt-2">
            <textarea
              spellCheck="false"
              id="prompt"
              className="block p-2.5 w-full text-sm text-gray-900  rounded-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
              placeholder="Din prompt"
              onChange={textChanged}
              onKeyDown={keyDown}
              value={prompt}
            ></textarea>
            {
              loading ? (
                <LoadingSpinner />
              ) : (
                <div></div>
              ) /*<SendButton></SendButton>*/
            }
          </form>
          {/*result ? <ResultsForm /> : null*/}
        </div>
      </div>

      <MyModal
        id="defaultModal"
        closingFunction={HideModal}
        visible={loadingError}
        title={errorTitle}
        content={errorMessage}
      ></MyModal>
    </div>
  );
}

export default Dalle;
