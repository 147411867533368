import "./App.css";
import React, { useState, CSSProperties } from "react";
import GridLoader from "react-spinners/GridLoader";
import Modal from "./modal";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCoffee, faQuestion } from "@fortawesome/free-solid-svg-icons";
import DaVinciPromptHelp from "./davinciprompthelp";
import { config } from "./Constants";
import Sidebar from "./Sidebar";

function Questionizr() {
  const [textToSend, setTextToSend] = useState("");
  const [textReceived, setTextReceived] = useState("");
  const [loading, setLoading] = useState(false);
  const [loadingError, setError] = useState(0);
  const [errorMessage, setErrorMessage] = useState("");
  const [errorTitle, setErrorTitle] = useState("");
  const [result, setResult] = useState(false);
  const [validInput, setValidInput] = useState(0);
  const [prompt, setPrompt] = useState("");

  const [showSidebar, setShowSidebar] = useState(false);

  const [conversation, setConversation] = useState([
    { role: "system", content: "You are a helpful assistant." },
  ]);

  let baseConversation = [
    { role: "system", content: "You are a helpful assistant." },
  ];

  const copyToClipboard = (event) => {
    // Get the text field
    var buttonElement = event.target;
    var parentDiv =
      buttonElement.parentElement.parentElement.parentElement.parentElement;

    var text = parentDiv.textContent;

    try {
      navigator.clipboard.writeText(text).then(function (x) {
        console.log("Content copied to clipboard");
      });
      navigator.clipboard.readText().then(function (x) {
        alert("Copied the text: " + x);
      });
    } catch (err) {
      console.error("Failed to copy: ", err);
    }
  };

  const conversationElement = conversation.map((turn, index) => {
    if (turn.role === "system") return;
    else if (turn.role === "user") return <></>;
    else
      return (
        <div key={index} className="pb-1">
          <div
            className="block p-2.5 w-full text-sm text-gray-900  rounded-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
            style={{ whiteSpace: "pre-wrap" }}
            readOnly
          >
            {turn.content}
            <div className="flex flex-row-reverse">
              <button onClick={copyToClipboard}>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke-width="1.5"
                  stroke="currentColor"
                  class="w-6 h-6"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    d="M9 12h3.75M9 15h3.75M9 18h3.75m3 .75H18a2.25 2.25 0 002.25-2.25V6.108c0-1.135-.845-2.098-1.976-2.192a48.424 48.424 0 00-1.123-.08m-5.801 0c-.065.21-.1.433-.1.664 0 .414.336.75.75.75h4.5a.75.75 0 00.75-.75 2.25 2.25 0 00-.1-.664m-5.8 0A2.251 2.251 0 0113.5 2.25H15c1.012 0 1.867.668 2.15 1.586m-5.8 0c-.376.023-.75.05-1.124.08C9.095 4.01 8.25 4.973 8.25 6.108V8.25m0 0H4.875c-.621 0-1.125.504-1.125 1.125v11.25c0 .621.504 1.125 1.125 1.125h9.75c.621 0 1.125-.504 1.125-1.125V9.375c0-.621-.504-1.125-1.125-1.125H8.25zM6.75 12h.008v.008H6.75V12zm0 3h.008v.008H6.75V15zm0 3h.008v.008H6.75V18z"
                  />
                </svg>
              </button>
            </div>
          </div>
        </div>
      );
  });

  const minimumValidTextLength = 10;

  const openModal = (event) => {
    var modal = document.querySelector(".modal");
    modal.classList.toggle("modal-open");
  };

  const keyDown = (event) => {
    if (event.code === "Enter") {
      console.log("Enter");
      event.stopPropagation();
      event.preventDefault();
      event.nativeEvent.stopImmediatePropagation();
      processPrompt();
      return false;
    }
  };

  const textChanged = (event) => {
    setPrompt(event.target.value);
    //setTextToSend(event.target.value);
    if (event.target.value.length > minimumValidTextLength) {
      setValidInput(1);
    } else {
      setValidInput(0);
    }
    event.preventDefault();
  };

  const processPrompt = () => {
    setPrompt("");

    let newTurn = {
      role: "user",
      content:
        "Skapa ett antal frågor och sant eller falskt svar baserad på följande text. Ange inga fråganummer. Skriv frågorna och sina svar som en rad i en .csv fil:" +
        prompt.replace(/[\r\n]/gm, ""),
    };

    let newConversation = baseConversation;
    newConversation.push(newTurn);
    setConversation(newConversation);

    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({ prompt: conversation }),
    };

    //https://reqres.in/api/posts
    setTextToSend("");
    setLoading(true);
    setResult(false);
    console.log(requestOptions);
    fetch(config.url.GPT_3POINT5_TURBO_URL, requestOptions)
      .then((response) => {
        const isJson = response.headers
          .get("content-type")
          ?.includes("application/json");
        const data = isJson ? response.json() : null;

        if (!response.ok) {
          // get error message from body or default to response status
          const error = (data && data.message) || response.status;
          return Promise.reject(error);
        }
        return data;
      })
      .then((data) => {
        console.log(data);
        let receivedText = data.choices[0].message;

        let newConversation = conversation;
        newConversation.push(receivedText);
        setConversation(newConversation);

        setResult(true);
        setLoading(false);
        if (receivedText.content.length == 0) {
          setErrorTitle("Tomt svar.");
          setErrorMessage(
            "GPT-3 algoritmen returnerade ett tomt svar. Detta är inget fel sig - prova att skicka om din prompt."
          );
          setError(1);
        }
      })
      .catch((error) => {
        console.log(error);

        setErrorTitle("Ett problem har uppstått.");
        setErrorMessage(
          "Tyvärr gick det inte att nå servern just nu. Var god försök igen senare"
        );
        setError(1);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const clickedButton = (event) => {
    event.stopPropagation();
    event.nativeEvent.stopImmediatePropagation();
    processPrompt();
  };

  const ResultsForm = () => {
    return (
      <form>
        <label htmlFor="response">Svar:</label>
        <textarea
          id="response"
          style={{ height: 30 + "rem" }}
          readOnly
          value={textReceived}
          className="u-full-width"
        ></textarea>
      </form>
    );
  };

  const LoadingSpinner = () => {
    return (
      <div className="box">
        <GridLoader loading={loading} />
      </div>
    );
  };

  const SendButton = () => {
    if (validInput) {
      return (
        <button
          type="button"
          className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
          onClick={clickedButton}
        >
          Skicka
        </button>
      );
    } else {
      return (
        <button
          type="button"
          disabled
          className="bg-gray-500 hover:bg-gray-700 text-white font-bold py-2 px-4 rounded"
          onClick={clickedButton}
        >
          Skicka
        </button>
      );
    }
  };

  return (
    <div className="text-gray-900 dark:text-white">
      <div className="p-4 sm:ml-64">
        <div className="p-4 border-2 border-gray-200 border-dashed rounded-lg dark:border-gray-700 mt-14">
          <h2>Questionizr</h2>
          <p>
            Klistra in en text eller ladda upp en fil som du vill skapa frågor
            om.
          </p>
          {conversationElement}
          <form className="pt-2">
            <textarea
              spellCheck="false"
              id="prompt"
              className="block p-2.5 w-full text-sm text-gray-900  rounded-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
              placeholder="Din prompt"
              onChange={textChanged}
              onKeyDown={keyDown}
              value={prompt}
            ></textarea>
            {
              loading ? (
                <LoadingSpinner />
              ) : (
                <div></div>
              ) /*<SendButton></SendButton>*/
            }

            <label
              className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
              htmlFor="file_input"
            >
              Ladda upp fil
            </label>
            <input
              className="block w-full text-sm text-gray-900 border border-gray-300 rounded-lg cursor-pointer bg-gray-50 dark:text-gray-400 focus:outline-none dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400"
              id="file_input"
              type="file"
            />
          </form>
          {/*result ? <ResultsForm /> : null*/}
        </div>
      </div>

      <Modal
        closingFunction={setError}
        visible={loadingError}
        title={errorTitle}
        content={errorMessage}
      ></Modal>
    </div>
  );
}

export default Questionizr;
