import "./App.css";
import React, { useState, useRef, CSSProperties } from "react";
import GridLoader from "react-spinners/GridLoader";
import MyModal from "./modal";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCoffee, faQuestion } from "@fortawesome/free-solid-svg-icons";
import DaVinciPromptHelp from "./davinciprompthelp";
import { config } from "./Constants";
import Sidebar from "./Sidebar";
import { Modal } from "flowbite";

function ChatGPT() {
  const [textToSend, setTextToSend] = useState("");
  const [textReceived, setTextReceived] = useState("");
  const [loading, setLoading] = useState(false);
  const [loadingError, setError] = useState(0);
  const [errorMessage, setErrorMessage] = useState("");
  const [errorTitle, setErrorTitle] = useState("");
  const [result, setResult] = useState(false);
  const [validInput, setValidInput] = useState(0);
  const [prompt, setPrompt] = useState("");
  const [modal, setModal] = useState(null);
  const [showModal, setShowModal] = React.useState(false);

  const [showSidebar, setShowSidebar] = useState(false);

  const [conversation, setConversation] = useState([
    { role: "system", content: "You are a helpful assistant." },
  ]);

  const removeDialog = (event) => {
    // Get the text field
    // event.stopPropagation();
    // event.preventDefault();
    // event.nativeEvent.stopImmediatePropagation();

    var buttonElement = event.target;

    while (buttonElement.nodeName != "BUTTON") {
      console.log(buttonElement.nodeName);
      buttonElement = buttonElement.parentElement;
    }

    console.log(buttonElement.nodeName);
    if (buttonElement.nodeName === "BUTTON") {
      console.log(buttonElement);
      let numberToDelete = buttonElement.dataset.number;
      console.log(numberToDelete);
      let newConversation = conversation;
      newConversation.splice(numberToDelete, 1);
      console.log(newConversation);
      setConversation([...newConversation]);
    }
  };

  const copyToClipboard = (event) => {
    // Get the text field
    var buttonElement = event.target;

    while (buttonElement.dataset.type != "clipboard") {
      console.log(buttonElement.nodeName);
      buttonElement = buttonElement.parentElement;
    }
    var parentDiv = buttonElement;

    var text = parentDiv.textContent;
    text = text.substring(8);

    try {
      navigator.clipboard.writeText(text).then(function (x) {
        console.log("Content copied to clipboard");
      });
      navigator.clipboard.readText().then(function (x) {
        console.log("Copied the text: " + x);
      });
    } catch (err) {
      console.error("Failed to copy: ", err);
    }
  };

  const conversationElement = conversation.map((turn, index) => {
    if (turn.role === "system") return;
    else if (turn.role === "user")
      return (
        <div key={index} className="pb-1">
          <div
            className="block p-2.5 w-full text-sm text-gray-900  rounded-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
            readOnly
          >
            <span>
              <b>Du: </b>
            </span>
            <span>{turn.content}</span>
            <div className="flex flex-row-reverse">
              <button onClick={removeDialog} data-number={index}>
                <span>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth="1.5"
                    stroke="currentColor"
                    className="w-6 h-6"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M14.74 9l-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 01-2.244 2.077H8.084a2.25 2.25 0 01-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 00-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 013.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 00-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 00-7.5 0"
                    />
                  </svg>
                </span>
              </button>
            </div>
          </div>
        </div>
      );
    else
      return (
        <div key={index} data-number={index} className="pb-1">
          <div
            className="block p-2.5 w-full text-sm text-gray-900  rounded-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
            data-type="clipboard"
            style={{ whiteSpace: "pre-wrap" }}
            readOnly
          >
            <span>
              <b>ED-GPT: </b>
            </span>
            {turn.content}
            <div className="mt-1 flex w-full  justify-between">
              <button onClick={copyToClipboard} data-number={index}>
                <div>
                  <svg
                    z-index="-5"
                    data-number={index}
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke-width="1.5"
                    stroke="currentColor"
                    class="w-6 h-6"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      d="M9 12h3.75M9 15h3.75M9 18h3.75m3 .75H18a2.25 2.25 0 002.25-2.25V6.108c0-1.135-.845-2.098-1.976-2.192a48.424 48.424 0 00-1.123-.08m-5.801 0c-.065.21-.1.433-.1.664 0 .414.336.75.75.75h4.5a.75.75 0 00.75-.75 2.25 2.25 0 00-.1-.664m-5.8 0A2.251 2.251 0 0113.5 2.25H15c1.012 0 1.867.668 2.15 1.586m-5.8 0c-.376.023-.75.05-1.124.08C9.095 4.01 8.25 4.973 8.25 6.108V8.25m0 0H4.875c-.621 0-1.125.504-1.125 1.125v11.25c0 .621.504 1.125 1.125 1.125h9.75c.621 0 1.125-.504 1.125-1.125V9.375c0-.621-.504-1.125-1.125-1.125H8.25zM6.75 12h.008v.008H6.75V12zm0 3h.008v.008H6.75V15zm0 3h.008v.008H6.75V18z"
                    />
                  </svg>
                </div>
              </button>
              <button onClick={removeDialog} data-number={index}>
                <div>
                  <span z-index="-5">
                    <svg
                      style={{ position: "relative" }}
                      data-number={index}
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      strokeWidth="1.5"
                      stroke="currentColor"
                      className="w-6 h-6"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M14.74 9l-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 01-2.244 2.077H8.084a2.25 2.25 0 01-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 00-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 013.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 00-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 00-7.5 0"
                      />
                    </svg>
                  </span>
                </div>
              </button>
            </div>
          </div>
        </div>
      );
  });

  const minimumValidTextLength = 1;

  const override: CSSProperties = {
    display: "block",
  };

  /*  const openModal = (event) => {
    var modal = document.querySelector(".modal");
    modal.classList.toggle("modal-open");
  }; */

  const keyDown = (event) => {
    if (event.code === "Enter") {
      console.log("Enter");
      event.stopPropagation();
      event.preventDefault();
      event.nativeEvent.stopImmediatePropagation();
      processPrompt();

      return false;
    }
  };

  const textChanged = (event) => {
    setPrompt(event.target.value);
    //setTextToSend(event.target.value);
    if (event.target.value.length > minimumValidTextLength) {
      setValidInput(1);
    } else {
      setValidInput(0);
    }
    event.preventDefault();
  };

  const processPrompt = () => {
    setPrompt("");
    setValidInput(0);

    let newTurn = { role: "user", content: prompt.replace(/[\r\n]/gm, "") };
    let newConversation = conversation;
    newConversation.push(newTurn);
    setConversation(newConversation);

    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({ prompt: conversation }),
    };

    const HideModal = () => {
      console.log("Calling hide");
      modal.hide();
    };

    const ShowModal = () => {
      // set the modal menu element
      // const targetEl = document.getElementById("defaultModal");
      // console.log(targetEl);
      // // options with default values
      // const options = {
      //   placement: "center-center",
      //   backdrop: "dynamic",
      //   backdropClasses:
      //     "bg-gray-900 bg-opacity-50 dark:bg-opacity-80 fixed inset-0 z-40",
      //   closable: true,
      //   onHide: () => {
      //     console.log("modal is hidden");
      //   },
      //   onShow: () => {
      //     console.log("modal is shown");
      //   },
      //   onToggle: () => {
      //     console.log("modal has been toggled");
      //   },
      // };
      // let modal = new Modal(targetEl, options);
      // setModal(modal);
      // modal.show();
    };

    //https://reqres.in/api/posts
    setTextToSend("");
    setLoading(true);
    setResult(false);
    console.log(requestOptions);
    fetch(config.url.GPT_3POINT5_TURBO_URL, requestOptions)
      .then((response) => {
        const isJson = response.headers
          .get("content-type")
          ?.includes("application/json");
        const data = isJson ? response.json() : null;

        if (!response.ok) {
          // get error message from body or default to response status
          const error = (data && data.message) || response.status;
          return Promise.reject(error);
        }
        return data;
      })
      .then((data) => {
        console.log(data);

        if (data.response > 200) {
          setErrorTitle("Ett fel har inträffat.");
          setErrorMessage(data.data.error.message);
          setShowModal(true);
        } else {
          let receivedText = data.choices[0].message;

          let newConversation = conversation;
          newConversation.push(receivedText);
          setConversation(newConversation);

          setResult(true);
          setLoading(false);
          if (receivedText.content.length == 0) {
            setErrorTitle("Tomt svar.");
            setErrorMessage(
              "Algoritmen returnerade ett tomt svar. Detta är inget fel sig - prova att skicka om din prompt."
            );
            setShowModal(true);
          }
        }
      })
      .catch((error) => {
        console.log(error);
        console.log(error.data);
        if (error.data.error.message != null) {
          setErrorMessage(error.data.error.message);
        } else {
          setErrorMessage(
            "Tyvärr gick det inte att nå servern just nu. Var god försök igen senare."
          );
        }
        setErrorTitle("Ett problem har uppstått.");

        setShowModal(true);
        setError(1);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const clickedButton = (event) => {
    event.stopPropagation();
    event.nativeEvent.stopImmediatePropagation();
    processPrompt();
  };

  const ResultsForm = () => {
    return (
      <form>
        <label htmlFor="response">Svar:</label>
        <textarea
          id="response"
          style={{ height: 30 + "rem" }}
          readOnly
          value={textReceived}
          className="u-full-width"
        ></textarea>
      </form>
    );
  };

  const LoadingSpinner = () => {
    return (
      <div className="box">
        <GridLoader loading={loading} cssOverride={override} />
      </div>
    );
  };

  const SendButton = () => {
    if (validInput) {
      return (
        <button
          type="button"
          className="mt-1 bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
          onClick={clickedButton}
        >
          Skicka
        </button>
      );
    } else {
      return (
        <button
          type="button"
          disabled
          className="mt-1 bg-gray-500 hover:bg-gray-700 text-white font-bold py-2 px-4 rounded"
          onClick={clickedButton}
        >
          Skicka
        </button>
      );
    }
  };

  return (
    <div className="text-gray-900 dark:text-white">
      <div className="p-4 ">
        <div className="p-4 border-2 border-gray-200 border-dashed rounded-lg dark:border-gray-700 mt-14">
          {conversationElement}
          <form className="pt-2">
            <textarea
              spellCheck="false"
              id="prompt"
              className="block p-2.5 w-full text-sm text-gray-900  rounded-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
              placeholder="Din prompt"
              onChange={textChanged}
              onKeyDown={keyDown}
              value={prompt}
            ></textarea>
            {/*loading ? (
                <LoadingSpinner />
              ) : (
                <div></div>
              ) 
          
          {/*result ? <ResultsForm /> : null*/}
            <SendButton></SendButton>
          </form>
        </div>
      </div>

      {showModal ? (
        <>
          {showModal ? (
            <>
              <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
                <div className="relative w-auto my-6 mx-auto max-w-2xl">
                  {/*content*/}
                  <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full dark:bg-gray-800 outline-none focus:outline-none">
                    {/*header*/}
                    <div className="flex items-start justify-between p-5 border-b border-solid border-slate-200 rounded-t">
                      <h3 className="font-semibold">{errorTitle}</h3>
                      <button
                        className="p-1 ml-auto bg-transparent border-0 text-black opacity-5 float-right text-3xl leading-none font-semibold outline-none focus:outline-none"
                        onClick={() => setShowModal(false)}
                      >
                        <span className="bg-transparent text-black opacity-5 h-6 w-6 text-2xl block outline-none focus:outline-none">
                          ×
                        </span>
                      </button>
                    </div>
                    {/*body*/}
                    <div className="relative p-6 flex-auto">
                      <p className="my-4 text-slate-500 leading-relaxed text-gray-900">
                        {errorMessage}
                      </p>
                    </div>
                    {/*footer*/}
                    <div className="flex items-center justify-end p-6 border-t border-solid border-slate-200 rounded-b">
                      <button
                        className="background-transparent font-bold uppercase px-6 py-2 text-sm outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                        type="button"
                        onClick={() => setShowModal(false)}
                      >
                        Stäng
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
            </>
          ) : null}
        </>
      ) : null}
    </div>
  );
}

export default ChatGPT;
