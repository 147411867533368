
import "./App.css";
import React, { useState, CSSProperties } from "react";


function DaVinciPromptHelp() {

  return  (
<div>
    <h2>Prompt Guidelines</h2>
    <p>
Brainstorm some ideas combining VR and fitness:
    </p>
    </div>
  );
 
}

export default DaVinciPromptHelp;
